import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import ScheduleItem from "../components/ScheduleItem";

const AttendeeProfile = ( { pageContext, data }) => {
    const { attendee } = pageContext
    const group = data.contentfulAttendee.schedule.title;

    const [isActive, setActive] = useState("Wed");

    const handleToggleWed = () => {
        setActive("Wed");
    };

    const handleToggleThur = () => {
        setActive("Thur");
    };

    return (
        <Layout>
            <div className="container px-8 py-16 mx-auto">
                <div className="flex items-center space-x-4">
                    <h1 className="mb-1">Hello, {attendee.name}</h1>
                    <div className={`w-8 h-8 flex-shrink-0 rounded-full ${group === "Schedule A" ? 'bg-green-700' : ''}${group === "Schedule B" ? 'bg-red-700' : ''}${group === "Schedule C" ? 'bg-blue-700' : ''}`}></div>
                </div>

                <p className="text-xl text-gray-400">Your schedule for the day ahead</p>

                <div className="flex space-x-3 border-b border-gray-300 border-solid">
                    <button
                        onClick={handleToggleWed}
                        className={`px-8 py-3 text-lg transition-colors duration-300 ease-in-out cursor-pointer ${isActive === "Wed" ? 'bg-[#002139] text-white' : 'bg-slate-100 hover:bg-slate-200'}`}>Wed
                    </button>
                    <button
                        onClick={handleToggleThur}
                        className={`px-8 py-3 text-lg transition-colors duration-300 ease-in-out cursor-pointer  ${isActive === "Thur" ? 'bg-[#002139] text-white' : 'bg-slate-100 hover:bg-slate-200'}`}>Thur
                    </button>
                </div>

                <div className={`my-8 ${isActive === "Wed" ? 'block' : 'hidden'}`}>
                    {data.day1Schedule.nodes.map(scheduleItem => (
                        <ScheduleItem key={scheduleItem.date_time} title={scheduleItem.title} date_time={scheduleItem.date_time} endTime={scheduleItem.endTime} location={scheduleItem.location} topic={scheduleItem.topic} presenters={scheduleItem.presenters} />
                    ))}

                    <div className="p-8 mt-8 text-white bg-[#002139]">
                        <h3>Prepare your tastebuds</h3>

                        <h4>You are sat at <span className="font-bold">Table Number {attendee.tableNumber}</span></h4>

                        <h4 className="mb-4">Starter:</h4>
                        <p>{attendee.starter}</p>

                        <h4 className="mb-4">Main Course:</h4>
                        <p>{attendee.mainCourse}</p>

                        <h4 className="mb-4">Dessert:</h4>
                        <p>{attendee.dessert}</p>
                    </div>

                </div>

                <div className={`my-8 ${isActive === "Thur" ? 'block' : 'hidden'}`}>
                    {data.day2Schedule.nodes.map(scheduleItem => (
                        <ScheduleItem key={scheduleItem.date_time} title={scheduleItem.title} date_time={scheduleItem.date_time} endTime={scheduleItem.endTime} location={scheduleItem.location} topic={scheduleItem.topic} presenters={scheduleItem.presenters} />
                    ))}

                    <div className="mt-8 p-8 bg-[#980321] text-white">
                        <h3>What you can look forward to today</h3>
                        <p>{attendee.activity1}</p>
                    </div>
                </div>

            </div>
        </Layout>
    )
}

export const Query = graphql`
    query ($id: String!, $schedule: String!) {
        contentfulAttendee(
            id: { eq: $id }
        ) {
            name
            schedule {
                scheduleItems {
                    location
                    date_time
                    endTime
                    title
                    presenters
                    topic {
                        raw
                    }
                }
                title
            }
        }
        day1Schedule : allContentfulScheduleItem(
            filter: {schedule: {elemMatch: {title: {eq: $schedule}}}, date: {eq: "2023-06-14"}}
            sort: {fields: date_time, order: ASC}
        ) {
            nodes {
                date
                date_time(formatString: "HH:mm")
                endTime(formatString: "HH:mm")
                title
                location
                presenters
                topic {
                    raw
                }
            }
        }
        day2Schedule : allContentfulScheduleItem(
            filter: {schedule: {elemMatch: {title: {eq: $schedule}}}, date: {eq: "2023-06-15"}}
            sort: {fields: date_time, order: ASC}
        ) {
            nodes {
                date
                date_time(formatString: "HH:mm")
                endTime(formatString: "HH:mm")
                title
                location
                presenters
                topic {
                    raw
                }
            }
        }
    }
`

export default AttendeeProfile;