import * as React from "react"
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p className="m-0">{children}</p>,
    [BLOCKS.UL_LIST]: (node, children) => {
        return <ul className="flex flex-col gap-2 pl-5 list-disc">{children}</ul>
    },
    [BLOCKS.LIST_ITEM]: (node, children) => {
        return <li>{children}</li>
    }
  },
}

const ScheduleItem = (props) => {

    const {title, date_time, endTime, location, presenters, topic} = props;

    return (
        <div className="p-8 text-lg border-b border-gray-300 border-solid">
            <div className="pb-3 text-xl font-semibold">{title}</div>
            
            {topic && <div className="mb-4">{renderRichText(topic, options)}</div>}

            {presenters && <div className="mb-4 font-semibold text-gray-600">{presenters}</div>}

            <div>
            
                {date_time} {endTime && `- ${endTime}`}
            
            </div>

            <div>{location}</div> 
        </div>
    )
}

export default ScheduleItem